$grid-columns: 12;
$grid-rows: 5;

.grid-system {

    grid-auto-flow: dense;
    column-gap: .5rem;
    row-gap: .5rem;

    @for $column from 1 to $grid-columns {

        @for $row from 1 to $grid-rows {

            &--#{$column}x#{$row} {
                grid-template-columns: repeat(#{$column}, 1fr);
                grid-template-rows: repeat(#{$row}, 1fr);
            }

            &--area {
                &-#{$column}x#{$row} {
                    grid-column-end: span $column;
                    grid-row-end: span $row;
                }

                &-top-right-#{$column}x#{$row} {    
                    grid-column-start: -1 - $column;
                    grid-column-end: -1;
                    grid-row-start: 1;
                    grid-row-end: 1 + $row;
                }
            }
        }
        
    }

}