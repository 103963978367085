@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&display=swap');
@import '@taiga-ui/core/styles/taiga-ui-local.scss';

@import "./grid";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    height: 100%;
    @apply antialiased;
}

:root {

    body,
    tui-root {
        @apply bg-base-alt [border-image:none]
    }

    // Inputs

    tui-input-inline {
        @apply flex #{!important};
    }

    [tuiWrapper][data-appearance='textfield'] {
        @apply shadow-none hover:shadow #{!important};
    }

    .t-cleaner {
        border-color: transparent !important;
    }

    // Buttons

    [tuiButton],
    [tuiIconButton] {
        @apply rounded-full font-medium #{!important};
    }

    [tuiButton] {
        @apply px-7 #{!important};
    }

    [tuiButton][data-size='s'] {
        @apply px-5 #{!important};
    }

    // Appearance

    [tuiAppearance][data-appearance='secondary'] {

        @apply bg-neutral-2;

        @include appearance-hover {
            @apply bg-neutral-2-hover;
        }

        @include appearance-active {
            @apply bg-neutral-2-pressed
        }

    }

    .required::after {
        content: '*';
        @apply text-negative ml-1 #{!important};
    }

    // Title

    [tuiTitle] {
        text-align: inherit;
    }

    [tuiSubtitle] {
        @apply text-secondary #{!important};
    }

    [tuiCardMedium][tuiTitle],
    [tuiCardLarge][tuiTitle],
    [tuiCardMedium] [tuiTitle]:not([tuiCell] *):not([tuiLabel] *),
    [tuiCardLarge] [tuiTitle]:not([tuiCell] *):not([tuiLabel] *) {
        font-weight: inherit;
    }

    // Table
    [tuiTh],
    [tuiTd] {
        border-inline-start: none;
        border-inline-end: none;
    }

    [tuiTable],
    [tuiTd] {
        background: none;
    }

    // Surface
    [tuiSurface][data-appearance=outline] {
        color: inherit;
        @apply bg-base;
        @apply shadow-none border border-solid border-border-normal #{!important};
    }

    // Skeleton
    .tui-skeleton::after {
        @apply bg-neutral-1;
    }

    // Badge
    tui-badge {
        @apply w-fit;
    }

    // Dialogs

    tui-dialog[data-size='page']>.t-filler {
        @apply grow-0;
    }

    tui-dialog[data-size='page']>.t-content {
        @apply p-0 h-full flex flex-col grow #{!important};

        section {
            @apply grow;
        }
    }

    tui-root:not(._mobile) tui-dialog:not([data-size='page'])>.t-content {

        &>.t-heading {
            @apply -mt-3 mb-4;
        }

        &>section {
            @apply bg-base-alt -mx-7 -mb-7 p-7 pt-6 rounded-b-[inherit] shadow-inner shadow-status-neutral/5;
        }

    }

    tui-root:not(._mobile) tui-dialog[data-size='s']>.t-content {

        &>.t-heading {
            @apply -mt-1;
        }

        &>section {
            @apply -mx-6 -mb-6 p-6 pt-4;
        }

    }

    tui-dialog h2.t-heading {
        font: var(--tui-font-heading-6);
    }

    // Tabs
    [tuiTab] {
        --tui-tab-margin: theme(spacing.9) !important;
    }

    [tuiTab]:not(._active) {
        @apply text-tertiary hover:text-secondary;
    }

    // Multi select
    tui-multi-select.hide-right-icon .t-textfield-icon {
        @apply hidden;
    }

    // Items with more
    tui-items-with-more {
        @apply gap-2;
    }

    // Hint
    tui-hint[data-appearance='warning'] {
        @apply bg-status-warning text-status-warning-pale;
    }

    tui-hint[data-appearance='success'] {
        @apply bg-status-positive-pale text-positive leading-tight shadow-lg shadow-status-positive/30;
    }

    // Tag

    tui-tag[data-status='default'] {
        @apply bg-neutral-1 text-secondary;

        &._hoverable:hover {
            @apply bg-neutral-1-hover;
        }

        &._hoverable:active {
            @apply bg-neutral-1-pressed;
        }

    }

    // Card

    [tuiCardLarge][data-space][data-space=normal] {
        --t-radius: theme(borderRadius.xl);
        --t-padding: theme(spacing[4]);
        --t-space: theme(spacing[4]);
    }

    // Link 

    [data-appearance='action'] {
        @apply text-blue-600 hover:text-action-hover #{!important};
    }

    [tuiLink][tuiAppearance]:not([data-appearance='navigation']) {
        @include appearance-hover {
            background: none;
            @apply underline;
        }

        @include appearance-active {
            background: none;
            @apply underline;
        }
    }

    .tui-link-active {
        @apply text-primary #{!important};
    }

    [tuiLink][data-appearance='navigation'] {
        @apply py-2 px-4 gap-3 transition-all rounded-xl hover:bg-base-alt text-secondary flex items-center #{!important};

        &::before {
            --tui-icon-size: theme(spacing.6);
        }

        &[data-collapsed='true'] {

            &[tuiChevron][tuiIcons]:after {
                @apply hidden #{!important};
            }

        }

        &[tuiChevron][tuiIcons]:after {
            @apply text-tertiary #{!important};
        }
    }

    tui-notification[data-collapsed='true'] {

        @apply justify-center transition-all #{!important};

        tui-svg {
            @apply mr-0 transition-all #{!important};
        }

        .t-content {
            @apply grow-0 shrink transition-all #{!important};
        }

    }

    // Chip
    tui-chip[tuiAppearance][data-appearance=neutral] {
        @apply text-secondary;
    }

}

// CDK Drag & Drop
.cdk-drag-animating {
    @apply transition-all;
}

.cdk-drag-preview {
    @apply shadow-xl;
}

// Gradient patient
@layer utilities {
    .bg-patient-gradient {
        background: linear-gradient(to bottom right, theme('colors.purple.100') 0%, white 50%);
        background-blend-mode: screen !important;
    }
}